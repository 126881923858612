import { useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "@remix-run/react";
import { createTheme, IconButton } from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  GetApp as GetAppIcon,
  ContentCopy,
  Restore as RestoreIcon,
} from "@mui/icons-material";
import TableHeader from "./TableHeader";
import { BasicBtn } from "./Buttons";
import StatusBadge from "./StatusBadge";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_TablePagination,
  type MRT_RowData,
  type MRT_ColumnDef,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_TableOptions,
  useMaterialReactTable,
  MRT_TableInstance,
  MRT_Row,
} from "material-react-table";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { ColumnSort } from "@tanstack/table-core";
import { useConfirmModal } from "~/hooks";
import { isNotNullish, Primitive } from "~/utils/tsUtils";

export type DeleteInfo = {
  deleteArgs: string[];
  name: string;
  errorMsg?: string;
  markAsDeleted?: boolean;
  confirmMsg?: string;
  toastMsg?: string;
};

// Copied from @tanstack/table-core (not exposed by material-react-table)
export interface ColumnFilter {
  id: string;
  value: unknown;
}
export type FilterQueryArgs = Record<string, Primitive>;

interface Props<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  rowCount?: number;
  searchText?: string | null;
  children?: React.ReactNode;
  title?: string;
  isWindowView?: boolean;
  page?: number;
  pageSize?: number;
  columns: MRT_ColumnDef<TData>[];
  columnOrder?: string[];
  data: TData[];
  selectedRows?: any;
  handleSearch?: (value: string) => void;
  handleChangePage?: (index: number, size: number) => void;
  handleFilterChange?: (filterValues: FilterQueryArgs) => void;
  handleDelete?: (...args: string[]) => Promise<unknown>;
  handleExport?: (rowData: TData) => void;
  handleEdit?: (rowData: TData) => void;
  handleRemove?: (rowData: TData) => void;
  handleRestore?: (id: string) => Promise<unknown>;
  isSearchable?: boolean;
  isPaginated?: boolean;
  isSortable?: boolean;
  clientSortOnly?: boolean;
  showColumnFilters?: boolean;
  isCompact?: boolean;
  tooltip?: string;
  isButtonDisabled?: boolean;
  isDisabled?: boolean;
  buttonText?: string;
  onClick?: () => void;
  status?: string;
  className?: string;
  isDraggable?: boolean;
  draggingRow?: MRT_Row<TData> | null;
  isNavigable?: boolean;
  handleDragEnd?: (table: MRT_TableInstance<TData>) => void;
  handleRowClick?: (id: string, status: boolean) => void;
  localization?: any;
  handleSort?: (sortArray?: ColumnSort[]) => void;
  handleRowStyle?: (rowData: TData) => string;
  handleRowSelectionChange?: (newValue: any) => void;
  useMemoDependencies?: string[];
  getIsDeletable?: () => void;
  sortValue?: ColumnSort;
  enableRowSelection?: boolean;
  enableMultiRowSelection?: boolean;
  isLoading?: boolean;
  layoutMode?: MRT_TableOptions<TData>["layoutMode"];
  getDeleteInfo?: (data: TData) => DeleteInfo;
  isMultiSort?: boolean;
}

export default function Table<TData extends MRT_RowData>({
  rowCount,
  searchText,
  children,
  title,
  isWindowView,
  page = 1,
  pageSize = 50,
  columns,
  data,
  selectedRows = {},
  handleSearch,
  handleChangePage,
  handleFilterChange,
  handleDelete,
  handleExport,
  handleEdit,
  handleRemove,
  handleDragEnd,
  handleRestore,
  isDraggable = false,
  enableRowDragging = false,
  onDraggingRowChange,
  draggingRow,
  isSearchable = false,
  isPaginated = false,
  isSortable = false,
  clientSortOnly = false,
  showColumnFilters = false,
  isCompact = false,
  tooltip = "",
  isButtonDisabled = false,
  isDisabled = false,
  buttonText = "",
  onClick,
  status,
  className,
  isNavigable = true,
  handleRowClick,
  localization,
  handleSort,
  handleRowStyle,
  handleRowSelectionChange,
  getIsDeletable,
  sortValue,
  enableRowSelection = false,
  enableMultiRowSelection = false,
  renderDetailPanel,
  isLoading,
  layoutMode = "semantic",
  getDeleteInfo,
  muiTablePaperProps,
  columnOrder,
  isMultiSort = false,
}: Props<TData>) {
  const currentPageIndex = page ? page - 1 : 0;

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: currentPageIndex,
    pageSize,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>(
    sortValue ? [sortValue] : []
  );

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (handleSort) {
      handleSort(sorting);
    }
  }, [sorting]);

  const onRowClick = (row: TData) => {
    const status = selectedRows[row?.id];
    handleRowClick?.(row?.id, !status);
  };

  useEffect(() => {
    if (handleChangePage) {
      const { pageIndex, pageSize } = pagination;
      handleChangePage(pageIndex + 1, pageSize);
    }
  }, [pagination]);

  useEffect(() => {
    if (handleFilterChange) {
      const filterValues: FilterQueryArgs = parseFilterData(columnFilters);
      handleFilterChange(filterValues);
    }
  }, [columnFilters]);

  function parseFilterData(filterData: ColumnFilter[]): FilterQueryArgs {
    const filterQueryArgs: FilterQueryArgs = {};
    filterData.forEach((cur) => {
      const value = cur.value;
      if (!isNotNullish(value)) {
        // Skip value
        return;
      }
      if (typeof value === "string") {
        filterQueryArgs[cur.id] = value.toLowerCase();
        return;
      }
      // Add types if needed
      if (typeof value === "number") {
        filterQueryArgs[cur.id] = value;
        return;
      }
    });
    return filterQueryArgs;
  }

  const safeGetIsDeletable = (getIsDeletable: (() => void) | undefined) => {
    if (getIsDeletable) return getIsDeletable();

    return true;
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableFilterMatchHighlighting: false,
    initialState: {
      showGlobalFilter: isSearchable,
      showColumnFilters,
      globalFilter: searchText,
      columnOrder,
    },
    state: {
      pagination,
      density: isCompact ? "compact" : "comfortable",
      columnFilters,
      rowSelection: selectedRows,
      sorting,
      showLoadingOverlay: isLoading,
      ...(draggingRow ? { draggingRow } : {}),
    },
    // dnd
    enableRowOrdering: isDraggable,
    enableRowDragging,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        handleDragEnd && handleDragEnd(table);
      },
    }),
    ...(onDraggingRowChange ? { onDraggingRowChange } : {}),
    muiSkeletonProps: {
      animation: "wave",
      height: 200,
    },
    // search
    muiSearchTextFieldProps: () => ({
      placeholder: "Search",
      sx: {
        minWidth: "18rem",
      },
      variant: "outlined",
    }),
    // row selection
    enableRowSelection,
    enableMultiRowSelection,
    getRowId: (row: TData) => row?.id,
    onRowSelectionChange: handleRowSelectionChange,
    // pagination
    enablePagination: isPaginated,
    manualPagination: isPaginated ?? false,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50],
    },
    onPaginationChange: setPagination,
    rowCount,
    // copy
    muiCopyButtonProps: {
      fullWidth: true,
      startIcon: <ContentCopy />,
      sx: { justifyContent: "flex-start" },
    },
    // sorting
    enableSorting: isSortable,
    enableMultiSort: isMultiSort,
    isMultiSortEvent: () => isMultiSort,
    manualSorting: isSortable && !clientSortOnly,
    onSortingChange: setSorting,
    // global filtering
    manualFiltering: true,
    onGlobalFilterChange: handleSearch && handleSearch,
    // col filtering
    onColumnFiltersChange: setColumnFilters,
    //localization
    localization,
    //layout
    layoutMode,
    // detail panel
    renderDetailPanel,
    enableExpandAll: !!renderDetailPanel,
    muiDetailPanelProps: ({ row }) => ({
      sx: {
        padding: row.getIsExpanded() ? "16px !important" : "0 16px !important",
      },
    }),
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
        transition: "transform 0.2s",
      },
    }),
    // table styles
    muiTablePaperProps,
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        color: row.getIsSelected() ? "#f45e45" : null,
        cursor: handleRowClick ? "pointer" : null,
        fontSize: "18px",
        backgroundColor: handleRowStyle
          ? handleRowStyle(row.original)
          : "transparent",
      },
      onClick: () => onRowClick(row.original),
    }),
    muiTableHeadRowProps: () => ({
      sx: {
        backgroundColor: "transparent",
      },
    }),
    muiTableHeadCellProps: () => ({
      sx: {
        color: "gray",
        textTransform: "uppercase",
        fontSize: "12px",
      },
    }),
    muiBottomToolbarProps: () => ({
      sx: {
        backgroundColor: "transparent",
      },
    }),
    // actions
    enableColumnActions: false,
    enableRowActions: true,
    positionActionsColumn: "last",
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 200, //make actions column wider
      },
    },
    renderRowActions: ({ row: { original } }) => {
      return (
        <div className="flex items-center gap-4 justify-end">
          <>
            {handleEdit && !isDisabled && (
              <IconButton onClick={() => handleEdit(original)} size="large">
                {!!original?.blsId ? <VisibilityIcon /> : <EditIcon />}
              </IconButton>
            )}
            {isNavigable && (
              <IconButton
                onClick={() =>
                  navigate(
                    `${pathname}${pathname.endsWith("/") ? "" : "/"}${
                      original.id
                    }`
                  )
                }
                size="large"
              >
                <EditIcon />
              </IconButton>
            )}
            {handleExport && (
              <IconButton onClick={() => handleExport(original)} size="large">
                <GetAppIcon />
              </IconButton>
            )}
            {handleRemove &&
              !isDisabled &&
              safeGetIsDeletable(getIsDeletable) && (
                <>
                  <IconButton
                    onClick={() => handleRemove(original)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            {(handleDelete || handleRestore) &&
              safeGetIsDeletable(getIsDeletable) && (
                <DeleteButton
                  getDeleteInfo={getDeleteInfo}
                  original={original}
                  handleDelete={handleDelete}
                  handleRestore={handleRestore}
                />
              )}
          </>
        </div>
      );
    },
    renderTopToolbar: ({ table }) => {
      return (
        <TableHeader>
          <div className="flex items-center gap-2 pl-[5px]">
            <MRT_GlobalFilterTextField table={table} />
            {children}
          </div>
          {isPaginated && (
            <div>
              <MRT_TablePagination table={table} />
            </div>
          )}
        </TableHeader>
      );
    },
  });

  return (
    <div className={`col-span-12 zoom ${className}`}>
      {title && (
        <div className="flex items-center gap-2.5 pb-4">
          <span className="basic-title">{title}</span>
          {tooltip}
          {isButtonDisabled || isDisabled || !buttonText ? null : (
            <BasicBtn text={buttonText} onClick={onClick} />
          )}
          {status && <StatusBadge status={status} />}
        </div>
      )}
      <div
        style={
          isWindowView
            ? { maxHeight: "415px", overflow: "hidden", overflowY: "auto" }
            : undefined
        }
      >
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
}

interface DeleteButtonProps<TData extends MRT_RowData> {
  getDeleteInfo?: (data: TData) => DeleteInfo;
  original: TData;
  handleDelete?: (...args: string[]) => Promise<unknown>;
  handleRestore?: (id: string) => Promise<unknown>;
}

function DeleteButton<TData extends MRT_RowData>({
  getDeleteInfo,
  original,
  handleDelete,
  handleRestore,
}: DeleteButtonProps<TData>) {
  const info = getDeleteInfo?.(original);

  const requestFn = info?.markAsDeleted ? handleRestore : handleDelete;

  // FIXME: this if statement makes the `useConfirmModal`hook conditional. -> Not Good.
  if (!info || !requestFn) {
    return null;
  }

  const [showConfirmModal, DeleteModal] = useConfirmModal(
    requestFn,
    info.deleteArgs,
    info.name,
    info.confirmMsg,
    info.toastMsg,
    info.errorMsg
  );

  return (
    <>
      <IconButton onClick={() => showConfirmModal()} size="large">
        {original?.deleted ? <RestoreIcon /> : <DeleteIcon />}
      </IconButton>
      <DeleteModal />
    </>
  );
}
