// app/styles/theme.ts
import { createTheme } from "@mui/material/styles";

export const kptnOrange = "#f35e43";

const theme = createTheme({
  palette: {
    primary: {
      main: kptnOrange,
    },
  },
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: kptnOrange, // Set spinner color to primary color (or use theme.palette.primary.main)
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: "flex", // Ensures alignment of text and icon
          alignItems: "center", // Center-aligns content vertically
          letterSpacing: "2px",
        },
        contained: {
          display: "flex", // Ensures alignment of text and icon
          alignItems: "center", // Center-aligns content vertically
          "&.Mui-disabled": {
            opacity: 0.5,
            color: "#fff",
            backgroundColor: kptnOrange,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: "32px",
          marginBottom: "32px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          backgroundColor: "#fff", // Input background color
          "&:hover": {
            backgroundColor: "#fafafa", // Input Hover background
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: kptnOrange, // border color on hover
            },
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: kptnOrange, // border color on focus
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: kptnOrange, // Border color when unchecked
          },
          "&.Mui-checked": {
            color: kptnOrange,
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: kptnOrange, // Label Color on Focus
          },
        },
        asterisk: {
          color: kptnOrange,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent", // Helper Text / Error Messages
        },
      },
    },
  },
});

export default theme;
