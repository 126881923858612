import { Button } from "@mui/material";
import { PrimaryBtn } from ".";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  onClick: () => void;
  text?: string;
  isDisabled?: boolean;
};

const CreateBtn = ({ onClick, text = "Create", isDisabled }: Props) => {
  return (
    <Button
      onClick={onClick}
      disabled={isDisabled}
      variant="contained"
      endIcon={<AddIcon />}
    >
      {text}
    </Button>
  );
};

export default CreateBtn;
