import { useState } from "react";
import { SecondaryBtn } from ".";
import { ApiResponse, createToastMessage } from "~/server/utils";
import useToastWithState from "~/hooks/useToastWithState";

type Props = {
  lokaliseFn: () => Promise<ApiResponse<undefined>>;
};

const LokaliseButton = ({ lokaliseFn }: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const showToast = useToastWithState();

  const handleLokalise = async () => {
    setIsFetching(true);
    let toastMessage;
    try {
      const res = await lokaliseFn();
      toastMessage = createToastMessage(res);
      showToast(toastMessage);
    } catch (error) {
      showToast({ msg: "Error on Lokalise", status: 400 });
    }

    setIsFetching(false);
  };

  return (
    <SecondaryBtn
      text={isFetching ? "Fetching..." : "Localise"}
      onClick={handleLokalise}
      disabled={isFetching}
    />
  );
};

export default LokaliseButton;
