import { Lang } from "~/types";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import useLocalStorage from "./useLocalStorage";

export interface LanguageContext {
  language: Lang;
  setLanguage: (lang: Lang) => void;
}

const languageContextDefault: LanguageContext = {
  language: "en",
  setLanguage: (language) => {},
};

export const LanguageContext = createContext<LanguageContext>(
  languageContextDefault
);

export function useLanguage() {
  return useContext(LanguageContext).language;
}

export function LanguageContextProvider({ children }: PropsWithChildren) {
  const [language, setLanguage] = useLocalStorage<Lang>("lang", "en");

  const languageContextValue = useMemo(
    () => ({
      language,
      setLanguage,
    }),
    [language, setLanguage]
  );

  return (
    <LanguageContext.Provider value={languageContextValue}>
      {children}
    </LanguageContext.Provider>
  );
}
